<template>
    <el-form @submit.prevent="onSubmit()" :model="form.data" ref="loginForm" class="form w-100" name="login-form">
        <!--begin::Heading-->
        <div class="text-center mb-16">
            <!--begin::Title-->
            <h1 class="text-dark fs-2x">{{ $t('pages.login.title') }}</h1>
            <!--end::Title-->
        </div>
        <!--begin::Heading-->
        <div class="fv-row mb-10" v-if="$root.appCrmStatus">
            <el-form-item prop="customer_id" :rules="$validation.getMessage(['required'])">
                <el-input v-model="form.data.customer_id" ref="customerID" type="text" :placeholder="$t('pages.login.cols.customerID')" :prefix-icon="User"/>
            </el-form-item>
        </div>

        <div class="fv-row mb-10">
            <el-form-item prop="username" :rules="$validation.getMessage(['requiredBlur', 'email'])" class="w-100" v-if="$root.appCrmStatus">
                <el-input v-model="form.data.username" ref="username" type="text" :placeholder="$t('pages.login.cols.email')" :prefix-icon="Message"/>
            </el-form-item>
            <el-form-item prop="email" :rules="$validation.getMessage(['requiredBlur', 'email'])" class="w-100" v-else>
                <el-input v-model="form.data.email" ref="username" type="text" :placeholder="$t('pages.login.cols.email')" :prefix-icon="Message"/>
            </el-form-item>
        </div>

        <div class="fv-row mb-10">
            <el-form-item prop="password" :rules="$validation.getMessage(['required'])" class="w-100">
                <el-input v-model="form.data.password" ref="password" :placeholder="$t('pages.login.cols.password')" :prefix-icon="Lock" show-password/>
            </el-form-item>
        </div>
        <!--<div class="fv-row mb-10">
            <el-form-item prop="login_confirmation" :rules="$validation.getMessage(['required'])" class="w-100 form-item-checkbox">
                <el-input type="hidden" v-model="form.data.login_confirmation"></el-input>
                <label class="form-check form-check-custom form-check-solid">
                    <input class="form-check-input" type="checkbox"  :true-value="1" :false-value="undefined" v-model="form.data.login_confirmation"/>
                    <span class="form-check-label text-gray-600">{{ $t('pages.login.cols.loginConfirmation') }}</span>
                </label>
            </el-form-item>
        </div>-->

        <!--begin::Actions-->
        <div class="text-center">
            <!--begin::Submit button-->
            <button :data-kt-indicator="form.loading ? 'on' : null" type="submit" :disabled="form.loading" class="btn btn-lg btn-primary w-100 mb-5">
                <span v-if="!form.loading" class="indicator-label">{{ $t("pages.login.btnText") }}</span>
                <span v-if="form.loading" class="indicator-progress">
                    {{  $t("messages.wait") }}
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            </button>
            <!--end::Submit button-->
        </div>
        <!--end::Actions-->
    </el-form>
</template>


<script>
import {Actions} from "@/store/enum/StoreEnums";
import {saveApp, getApp, destroyApp } from "@/core/services/App";
import * as Yup from "yup";
import { User, Message, Lock } from '@element-plus/icons'

export default {
    layout: 'new-version-auth',
    name: "login",
    data(){
        return {
            User,
            Message,
            Lock,
            form: {
                loading: false,
                data: {}
            },
        }
    },
    mounted(){
        if(this.$refs.customerID) {
            this.$refs.customerID.input.classList = "el-input__inner form-control form-control-lg form-control-solid";
            this.form.data.customer_id = getApp('customerID');
        }
        this.$refs.username.input.classList = "el-input__inner form-control form-control-lg form-control-solid";
        this.$refs.password.input.classList = "el-input__inner form-control form-control-lg form-control-solid";
    },
    methods: {
        onSubmit(){
            this.$refs.loginForm.validate((valid) => {
                if(valid) {
                    this.form.loading = true;

                    this.$store.dispatch(Actions.LOGOUT);

                    if(this.$root.appCrmStatus){
                        this.axios.post(process.env.VUE_APP_CRM_URL, this.form.data).then((response) => {
                            if(response.data.status){
                                let data = response.data.detail;

                                saveApp('url', data.api_url);
                                saveApp('customerID', data.customer_id);
                                this.axios.defaults.baseURL = getApp('apiUrl');

                                this.form.data.email = this.form.data.username;

                                this.loginAction();
                            } else {
                                this.form.loading = false;
                                destroyApp('url');

                                this.$swal.fire({
                                    text: this.$t('auth.errors.' + response.data.error, response.data.error),
                                    icon: "error",
                                    buttonsStyling: false,
                                    confirmButtonText: this.$t('btn.ok'),
                                    customClass: {
                                        confirmButton: "btn fw-bold btn-light-danger",
                                    },
                                });
                            }
                        })
                    } else {
                        this.loginAction();
                    }
                } else {
                    return false;
                }
            });
        },
        loginAction(){
            this.$store.dispatch(Actions.LOGIN, this.form.data).then(() => {
                // Go to page after successfully login
                this.$router.push({name: "dashboard"});
                /*this.$swal.fire({
                    text: this.$t('pages.login.successLogin'),
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText:  this.$t('btn.ok'),
                    customClass: {
                        confirmButton: "btn fw-bold btn-light-primary",
                    },
                }).then(() => {
                    this.$root.initialize();
                    // Go to page after successfully login
                    this.$router.push({name: "dashboard"});
                });*/
            }).catch(() => {
                this.$swal.fire({
                    text: this.$store.getters.getErrors[0] == 'invalid_credentials' ? this.$t('pages.login.responseMessages.invalidCredentials') : this.$store.getters.getErrors[0],
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: this.$t('btn.ok'),
                    customClass: {
                        confirmButton: "btn fw-bold btn-light-danger",
                    },
                });
            }).finally(() => {
                this.form.loading = false;
            });
        }
    }
}
</script>

<style>
    form[name='login-form'] .el-input .el-icon{
        color: #5E6278 !important;
        font-size: 16px;
        width: 30px;
    }
    form[name='login-form'] .el-input--prefix .el-input__inner{
        padding-left: 40px !important;
    }

    form[name='login-form'] .el-form-item.is-error.form-item-checkbox .el-form-item__content  {
        border: 1px solid var(--el-color-danger);
        padding: 5px 10px;
        border-radius: 0.475rem;
    }
    form[name='login-form'] .form-item-checkbox .el-form-item__content{
        line-height: 1.5;
        font-size: 0.9375rem !important;
    }
</style>